.questions {
  display: flex;
  padding: 60px 50px;
  border: 1px solid rgba($color: #d8e3e6, $alpha: 0.7);
  margin-top: 60px;
  margin-bottom: 60px;
  background-color: #f8fbff;
  @media screen and (max-width: 940px) {
    display: block;
  }

  .questions-left {
    padding-right: 60px;

    h2 {
      margin-bottom: 20px;
    }

    .questions-text {
      font-weight: 400;
      line-height: 32px;
    }

    @media screen and (min-width: 941px) {
      width: 60%;
      border-right: 1px solid rgba($color: #d8e3e6, $alpha: 0.7);
    }
  }

  .questions-right {
    margin-top: 1rem;
    color: #0c3543;

    h5 {
      margin-bottom: 20px;
    }

    .contact-link {
      display: flex;
      align-items: start;
      column-gap: 10px;

      &:first-of-type {
        margin-bottom: 1em;
      }

      &:nth-of-type(2) {
        margin-bottom: 5px;
      }

      img {
        padding-top: 5px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
      }

      a {
        color: #0c3543 !important;

        &:hover {
          color: #1d9bf0 !important;
        }
      }
    }

    .contact-opening-hours {
      display: block;
      padding-left: 26px;
      font-size: 16px;
      font-weight: 300;
    }

    @media screen and (min-width: 941px) {
      width: 40%;
      padding-left: 60px;
      margin-top: 0;
    }
  }

  &:has(+ .questions) {
    padding-bottom: 1rem;
    border-bottom: 0;
    margin-bottom: 0;
  }

  + .questions {
    position: relative;
    padding-top: 1rem;
    border-top: none;
    margin-top: 0;

    &::before {
      position: absolute;
      width: calc(100% - 100px);
      border-top: 1px solid rgba(216, 227, 230, 0.7);
      margin-top: -1rem;
      content: '';
    }

    &:has(.questions-left:empty) {
      padding-top: 0;
      margin-top: -1rem;

      &:before {
        content: none;
      }
    }
  }
}
