#page-document.project {
  position: relative;
  max-width: var(--layout-container-width);
  margin: 40px auto 0;

  .project-flex {
    display: flex;
    padding: 0 1rem;
    column-gap: 8%;
  }

  .project-flex:has(.headerimage img.responsive) {
    padding-top: 440px;
  }

  .project-left {
    width: 66%;
    height: fit-content;

    .blocks-group-wrapper {
      padding: 0;

      .headerimage {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 8% + 25%);
        margin-left: -1rem;

        img {
          width: 100%;
          height: 400px;
          margin-bottom: 40px;
          object-fit: cover;
        }
      }

      h1 {
        margin-top: 0;
      }

      h2 {
        margin-bottom: 25px;
      }
    }
  }

  .project-right {
    width: 25%;
    min-width: 300px;
    padding-top: 10px;
    margin-top: 30px;

    .right-side-section {
      margin-bottom: 80px;

      h5 {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(12, 53, 67, 0.1);
        margin-bottom: 30px;
      }

      .partners {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        column-gap: 50px;
        row-gap: 20px;

        img {
          width: 100%;
        }
      }

      .details {
        .detail-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          column-gap: 40px;

          p {
            margin: 0;
            color: #0c3543;
            font-size: 15px;
          }

          a {
            margin: 0;
            font-size: 15px;
          }

          :nth-child(1) {
            text-wrap: nowrap;
          }

          :nth-child(2) {
            font-weight: 400;
            text-align: right;
          }

          a:nth-child(2) {
            color: #1d9bf0;
            font-weight: 700;
            text-decoration: underline;
          }

          .project-status {
            font-weight: 700;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 940px) {
    .project-flex {
      display: block;
    }

    .project-left,
    .project-right {
      width: 100%;
    }
  }
}
