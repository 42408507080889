.carousel {
  overflow: hidden;
  margin: 0 -20px;

  .grid.ui {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    > .column:not(.row) {
      width: 90% !important;
      flex-shrink: 0;
      padding: 0 20px !important;
      scroll-snap-align: center;
    }
  }

  .carousel__pages {
    position: absolute;
    right: 0;
    bottom: 20px;
    left: 0;
    display: flex;
    justify-content: center;

    .carousel__page {
      width: 7px;
      height: 7px;
      border: none;
      border-radius: 99px;
      background: $primary-color;
      cursor: pointer;
      opacity: 0.1;
      outline: none;
      transition:
        width 0.2s ease,
        opacity 0.2s ease;

      .has--backgroundColor--dark & {
        background: white;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }

      &--selected {
        width: 23px;
        opacity: 1;
      }
    }
  }
}

.block.listing .a.container.layout:has(.carousel) {
  position: unset;
  container-type: unset;
}
