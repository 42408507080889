.dsgvo-cookie-banner,
.consentblock {
  * {
    color: #0c3543;
  }

  a {
    color: #1d9bf0;

    &:hover {
      text-decoration: underline;
    }
  }

  .actions button.ui.button {
    margin: 5px;

    &.branded.blue {
      background: #0c3543;
      box-shadow: none !important;
      color: white;

      &:hover {
        background: #1d9bf0;
      }
    }

    &.back,
    &.branded.olive {
      border: 1px solid #e7edef;
      background: white;
      color: inherit;

      &:hover {
        background-color: #edf4f6;
      }
    }
  }

  &.back:first-letter {
    display: inline-block !important;
    min-width: 18px;
    min-height: 18px !important;
    padding: 0 0.3rem;
    border-radius: 50%;
    margin-right: 0.1rem;
    background: rgba(29, 155, 240, 0.1);
    color: #1d9bf0;
  }

  .ui.toggle.checkbox {
    label {
      padding-left: 23px;
      color: #0c3543;
      font-weight: 500;

      &:after {
        background: transparent;
      }
    }

    input:focus ~ label:before,
    label:before {
      top: 0.2rem;
      width: 17px;
      height: 17px;
      border: 1px solid rgba(216, 227, 230, 0.7);
      border-radius: 0;
      background: white;
    }

    input:checked ~ label:before {
      background: rgba(29, 155, 240, 0.1);
      opacity: 0.1;
    }
  }

  .ui.checkbox input:checked ~ label:after {
    top: 0.2rem;
    left: 0;
    width: 17px;
    height: 17px;
    border-radius: 0;
    margin: 0;
    background: rgba(29, 155, 240, 0.1);
    color: #1d9bf0;
    content: '\E800' !important;
    font-family: Checkbox;
  }
}

.consentblock {
  padding: calc(30px - 1.2em) 30px 30px;
  border: 1px solid #0c3543;
  margin-bottom: 60px;

  button.ui.button {
    margin: 5px;

    &.branded.blue {
      background: #0c3543;
      color: white;

      &:hover {
        background: #1d9bf0;
      }
    }
  }
}
