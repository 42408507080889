#page-add,
#page-edit,
#page-document {
  .line:after {
    border-color: rgba($primary-color, 0.1);
  }

  .block.separator {
    &:not(.has--align--full) {
      margin-top: $spacing-block;
      margin-bottom: $spacing-block;
    }

    &.has--align--full {
      margin-top: 30px;

      .line:after {
        border: none;
      }
    }
  }
}
