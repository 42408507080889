.snel-naar-block {
  width: 100% !important;
  padding: 15px 50px;
  border: 1px solid #d8e3e6;
  margin-top: 20px;
  background-color: red($color: #cd2e2e) !important;

  a.external::after {
    content: none !important;
  }

  .flex {
    display: flex;
    width: 100%;
    align-items: top;
    justify-content: space-between;

    svg {
      margin-top: 7px;
    }
  }

  .snbText {
    width: 80%;
    margin-top: 5px;
    color: #0c3543;
    font-size: 18px;
    line-height: 26px;
    word-break: keep-all;
  }

  .snbTitle {
    width: 80%;
    margin: 0;
    color: #0c3543;
    font-size: 21px;
    font-weight: 500;
    line-height: 120%;
  }
}

.snel-naar-block:hover {
  background-color: #edf4f6;
  cursor: pointer;
}
