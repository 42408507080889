#view .socials-block,
#page-edit .socials-block {
  .container {
    display: flex;
    flex-direction: column;
  }

  .container > div {
    width: 100%;

    &:first-child {
      > h2 {
        margin-bottom: 16px;
      }

      > p {
        margin-bottom: 32px;
      }
    }
  }

  .socials-block__links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    > div {
      display: flex;
    }

    p {
      margin: 0;
      margin-right: 12px;
    }

    a {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }
  }

  .socials-block__news-letter-title {
    margin-bottom: 12px;
  }

  form.socials-block__news-letter-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 32px;

    > label {
      flex: 1;
      margin-bottom: 16px;
    }

    input#news-letter-input {
      margin-top: 15px;
      outline: none;
    }

    button.button:hover {
      filter: none;
    }
  }

  .error {
    color: #df3032;

    + form {
      label {
        color: #df3032;
      }

      input#news-letter-input {
        border-color: #df3032;
      }
    }
  }

  @media only screen and (min-width: $tablet-breakpoint) {
    form.socials-block__news-letter-form {
      flex-direction: row;
      align-items: flex-end;

      > label {
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (min-width: $computer-breakpoint) {
    .container {
      flex-direction: row;
      align-items: flex-end;
    }

    .container > div {
      width: 50%;

      &:last-child {
        padding-left: 48px;
      }

      &:first-child {
        padding-right: 48px;
      }
    }

    .socials-block__links {
      flex-direction: row;
      align-items: center;
    }

    form.socials-block__news-letter-form {
      margin-bottom: 0;
    }
  }
}

#page-add .blocks-group-wrapper,
#page-edit .blocks-group-wrapper,
#page-document .blocks-group-wrapper,
.blocks-group-wrapper {
  &.light:has(.block.socials-block) {
    background: #edf4f6 url('../../../assets/footer_background.png') 50%;
  }
}
