#view .indicator,
#page-edit .indicator {
  display: flex;
  flex-direction: column;
  padding: 16px;

  --text-color: white;

  &:hover {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
  }

  &.indicator--dark {
    --text-color: #{$primary-color};

    .indicator__content {
      img {
        filter: invert(15%) sepia(9%) saturate(5749%) hue-rotate(155deg)
          brightness(95%) contrast(91%);
      }
    }
  }

  &:not(:last-child) {
    border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
  }

  &.indicator--dark:not {
    border-color: rgba(#0c3543, 0.1);
  }

  p {
    margin: 0;
    line-height: 23px;
  }

  .indicator__name {
    color: var(--text-color);
    font-size: 13px;
    font-weight: 300;
  }

  .indicator__content {
    display: flex;
    align-items: center;
    margin-top: 4px;

    > div {
      position: relative;
      width: 100%;
      padding-right: 20px;

      &:after {
        position: absolute;
        top: 6px;
        right: 0;
        width: 8px;
        height: 13px;
        padding-left: 10px;
        background-image: url('data:image/svg+xml;utf8,<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6.15 6.15C6.19783 6.19489 6.23595 6.24911 6.26201 6.30931C6.28807 6.3695 6.30152 6.4344 6.30152 6.5C6.30152 6.5656 6.28807 6.6305 6.26201 6.69069C6.23595 6.75089 6.19783 6.80511 6.15 6.85L1 12" stroke="%23ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>');
        background-repeat: no-repeat;
        content: '';
      }

      @media screen and (min-width: 481px) and (max-width: 940px) {
        padding-right: initial;

        &:after {
          content: unset;
        }
      }
    }

    img {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 12px;
      margin-right: 16px;

      filter: invert(1);
      object-fit: contain;
    }

    > div {
      .indicator__status {
        display: flex;
        align-items: center;
        color: var(--text-color);
        font-size: 20px;
        font-weight: 500;

        span {
          display: inline-block;
          width: 25px;
          height: 10px;
          border-radius: 5px;
          margin-right: 8px;
        }
      }

      .indicator__future-status {
        color: var(--text-color);
        font-size: 15px;
        font-weight: 400;

        span {
          font-size: 15px;
          font-weight: 200;
        }
      }
    }
  }

  &.indicator--vertical {
    border-bottom: none;

    &:not(:last-child) {
      border-right: rgba(255, 255, 255, 0.1) 1px solid;
    }

    .indicator__name {
      opacity: 0.9;
      text-align: center;
    }

    .indicator__content {
      flex-direction: column;
      align-items: center;

      img {
        margin: 16px 0;
      }

      .indicator__status {
        justify-content: center;
        margin-bottom: 4px;
        font-size: 13px;

        span {
          width: 15px;
          height: 6px;
        }
      }

      .indicator__future-status {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1;

        span {
          display: block;
          opacity: 0.5;
        }
      }
    }
  }
}
