#view .indicator-header,
#page-edit .indicator-header {
  max-width: unset !important;
  margin-right: -$horizontal-page-padding;
  margin-left: -$horizontal-page-padding;

  .indicator-header__container {
    padding: 48px $horizontal-page-padding;
    padding-top: 140px;
    background-position: center;
    background-size: cover;

    .indicator-header__content {
      display: flex;
      max-width: var(--layout-container-width);
      margin: 0 auto;

      .indicator-header__text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1,
        p {
          color: white;
          font-weight: 500;
          text-shadow: 0px 14px 74px rgba(7, 35, 45, 0.4);
        }

        h1 {
          font-size: 34px;
          line-height: 1.1;
        }

        p {
          font-size: 17px;
          font-weight: 400;
          line-height: 1.5;
        }
      }
    }
  }

  &.indicator-header--small {
    .indicator-header__container {
      padding-top: 48px;
    }
  }

  &.indicator-header--blank {
    .indicator-header__container {
      background: #f8fbff;

      .indicator-header__content .indicator-header__text {
        h1,
        p {
          color: $primary-color;
          text-shadow: none;
        }
      }
    }
  }

  &.indicator-header--mobile {
    .indicator-header__indicators {
      padding: 20px;
      margin-top: 10px;
      background: $senary-color;

      .indicator-header__indicators-list {
        display: flex;
        overflow: hidden;

        .indicator__status {
          min-width: 4rem;
          font-size: 1rem;
        }

        .indicator__status-color {
          width: 25px;
          height: 11px;
        }

        @media only screen and (max-width: 480px) {
          display: block;

          .indicator.indicator--vertical {
            border-right: none;
            //align-items: flex-start;

            .indicator__name {
              width: 100%;
              padding-bottom: 8px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              font-weight: 500;
              text-align: left;
              text-transform: uppercase;
            }
          }
        }

        .indicator {
          flex: 1;
        }
      }
    }
  }

  @media only screen and (min-width: $computer-breakpoint) {
    &.indicator-header--small
      .indicator-header__container
      .indicator-header__content
      .indicator-header__text {
      margin-top: 48px;
    }

    .indicator-header__container {
      padding-top: 0;
      padding-bottom: 0;

      .indicator-header__content {
        justify-content: space-between;
        padding: 48px 0;

        .indicator-header__text {
          width: 668px;
          margin-top: 220px;
          margin-right: 80px;
          margin-bottom: 48px;

          h1 {
            font-size: 69px;
          }

          p {
            font-size: 28px;
            font-weight: 300;
          }

          .button {
            align-self: flex-start;
            margin-top: 60px;
          }
        }

        .indicator-header__indicators {
          display: flex;
          width: 33%;
          min-width: 300px;
          max-width: 400px;
          flex-direction: column;
          flex-shrink: 0;
          justify-content: flex-end;
          background: none;

          > div {
            width: 100%;
            margin-top: 16px;

            &:empty {
              display: none;
            }
          }
        }
      }
    }

    &.indicator-header--blank {
      .indicator-header__container {
        .indicator-header__content {
          flex-direction: column;
          gap: 48px;

          > div {
            width: 100%;
            max-width: unset;
          }

          .indicator-header__indicators {
            align-items: flex-end;
            justify-content: unset;
            gap: 48px;

            > div {
              width: auto;
              margin-top: 0;
            }

            .indicator-header__indicator-card {
              display: flex;
              padding: 32px 0;

              > div {
                padding: 0 32px;

                &:not(:last-child) {
                  border-right: 1px rgba($primary-color, 0.1) solid;
                }
              }

              &:empty {
                display: none;
              }
            }
          }

          .indicator-header__text {
            margin: 0;
          }

          .indicator-header__indicators {
            flex-direction: row;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .indicator-header__indicators-list {
      display: block;
    }

    .indicator.indicator--vertical {
      padding: 0 0 16px 0;

      .indicator__content {
        flex-direction: row;

        .indicator__status {
          margin-left: 20px;
        }
      }
    }

    .warnings {
      padding: 0 0 16px 0;
    }
  }
}
