.vmm-input {
  display: block;
  width: 100%;
  padding: 20px;
  border: 1px solid rgba(216, 227, 230, 0.7);

  border-radius: 10px;
  background: #fff;

  color: $primary-color;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  transition: border-color 0.2s ease;

  &:hover,
  &:focus {
    border-color: $secondary-color;
  }
}
