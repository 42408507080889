.page-not-found .ui.container.view-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-content: flex-start;
  justify-content: space-between;
  gap: 30px;

  img.error-page-icon {
    max-width: 300px;
    height: fit-content;
  }

  button.button a {
    color: white;
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 60px;
  }
}
