#view,
#page-edit,
#navigation {
  .button.container a:after {
    display: none;
  }

  a.button,
  button.button {
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    border-radius: 999px;
    appearance: none;

    font-family: 'Flanders-art-sans', sans-serif;
    font-weight: 500;
    gap: 10px;
    text-align: center;
    transition: filter 0.2s ease;

    transition:
      border-color 0.2s ease,
      background 0.2s ease,
      color 0.2s ease;

    span {
      border: none;
      color: inherit !important;
      hyphens: auto;
      word-break: break-all;
    }

    img {
      margin: 0;
    }

    &.button--senary {
      background: $senary-color;
      color: white !important;
    }

    &.button--secondary {
      background: $secondary-color;
      color: white !important;
    }

    &.button--secondary:hover {
      background: $tertiary-color;
    }

    &.button--tertiary {
      background: $tertiary-color;
      color: white !important;
      color: white;
    }

    &.button--tertiary:hover {
      background: $primary-color;
    }

    &.button--primary {
      background: $primary-color;
      color: white !important;
    }

    &.button--primary:hover {
      background: $secondary-color;
      filter: drop-shadow(0px 4px 60px rgba(29, 155, 240, 0.5));
    }

    &.button--border {
      border: 1px solid #d8e3e6;
      background: transparent;
      color: $primary-color !important;
    }

    &.button--border:hover {
      background: #edf4f6;
    }

    &.button--medium {
      padding: 14px 25px;
      font-size: 18px;
    }

    &.button--large {
      padding: 20px 40px;
      font-size: 18px;

      &.button--square {
        padding: 20px;
      }
    }

    &.button--input {
      padding: 10px 20px;
      font-size: 16px;
    }

    &.button--half-rounded {
      border-radius: 10px;
    }

    &.button--glow {
      box-shadow: 0px 4px 124px 0px rgba(85, 255, 186, 0.4);
    }

    @media only screen and (min-width: $computer-width) {
      img {
        width: 20px;
      }

      &.button--large {
        padding: 22px 45px;
        font-size: 21px;

        img {
          width: 22px;
        }

        &.button--square {
          padding: 22px;
        }
      }
    }
  }
}

#view .button:hover {
  cursor: pointer;
}
/* quick fix to override VLT padding where they are too much */
.block.__button {
  .button.container {
    max-width: unset;
  }

  p + & {
    .button.container {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}
