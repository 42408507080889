.teaser.standalone-teaser {
  padding: 24px 0;

  .teaser-item {
    border-bottom: none !important;

    .image-wrapper {
      flex-shrink: 0;
      align-self: center;
      margin: 0;
      aspect-ratio: 5/4;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .teaser__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 24px;

      .teaser__title-underline {
        width: 160px;
        height: 1px;
        background: rgba(#d8e3e6, 0.7);
      }

      .button {
        align-self: flex-start;
      }
    }
  }

  &.has--align--right {
    .teaser-item {
      flex-direction: row-reverse;
    }
  }

  &.mobile .teaser-item {
    .image-wrapper {
      aspect-ratio: 16/9;
    }
  }
}
