#page-document.publication {
  display: flex;
  max-width: var(--layout-container-width);
  margin: 40px auto 0;
  column-gap: 8%;

  .blocks-group-wrapper {
    padding: 0;
  }

  .publication-left {
    width: 66%;
    height: fit-content;

    h2 {
      margin-bottom: 25px;
    }
  }

  .publication-right {
    width: 25%;
    min-width: 300px;
    padding-top: 30px;
    margin-top: 10px;
  }

  @media only screen and (max-width: 940px) {
    display: block;

    .publication-left,
    .publication-right {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1750px) {
  #page-document.publication {
    padding: 0 2rem;
  }
}
