#footer .footer.flanders-footer {
  padding-top: 28px;
  padding-bottom: 24px;
  border-top: #cbd2da 1px solid;
  background: #fff;
  color: #333333;
  font-size: 14px;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > *:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  .flanders-footer__logo.mobile {
    display: block;
    width: 140px;
  }

  .flanders-footer__logo.desktop {
    display: none;
    width: 140px;
  }

  .flanders-footer__content {
    text-align: left;

    h2 {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
    }

    p {
      margin-bottom: 16px;
      font-size: 14px;
    }

    a {
      color: #333333;
      font-size: 14px;
      text-decoration: underline;

      &:not(:last-child):after {
        display: inline-block;
        margin: 0 8px;
        color: #cbd2da;
        content: '|';
      }
    }
  }

  .flanders-footer__languages {
    a {
      color: #333333;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media only screen and (min-width: $computer-breakpoint) {
    padding-bottom: 28px;

    > div {
      flex-direction: row;

      > * {
        margin-bottom: unset;
      }
    }

    .flanders-footer__logo.mobile {
      display: none;
    }

    .flanders-footer__logo.desktop {
      display: block;
    }

    .flanders-footer__languages {
      align-self: flex-end;
    }
  }
}
