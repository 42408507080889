.block-quote-footer {
  display: flex;
  align-items: center;
  margin-top: 15px;
  gap: 15px;
}

.block-quote-text {
  font-size: 34px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 47.6px;
  text-align: left;
}

.block-quote-image {
  width: 52px;
  height: 52px;
  border-radius: 35px;
}

.block-quote-author {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 23.4px;
  text-align: left;
}

.block-quote-information {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  line-height: 18.2px;
  text-align: left;
}

.block-editor-quote :focus-visible {
  outline: none;
}

.block-editor-quote blockquote > div {
  margin-bottom: 1em;
}

html {
  &[lang='de'] {
    .block.quote blockquote:not(.isEmpty) {
      p {
        &::before {
          content: '„';
        }

        &::after {
          content: '“';
        }
      }
    }
  }

  &[lang='en'] {
    .block.quote blockquote:not(.isEmpty) {
      p {
        &::before {
          content: '"';
        }

        &::after {
          content: '"';
        }
      }
    }
  }
}
