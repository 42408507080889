#page-add,
#page-edit,
#page-document {
  .block.form {
    h2 {
      display: inline-block;
      padding-bottom: 20px;
      border-bottom: 1px solid $secondary-color;
      margin-bottom: 48px;
      font-size: 34px;
      line-height: 1.1;
    }

    .description {
      margin-bottom: 48px;
    }

    .column {
      display: block !important;
      width: 100% !important;
      text-align: left;
    }

    .ui.padded.segment {
      top: 30px;
      padding: 0;
      border: none;
      box-shadow: none;
    }

    button.ui.button {
      display: inline-block;
      padding: 24px 34px;
      border-radius: 10px;
      margin: 0;

      + button.ui.button {
        margin-left: $spacing-xlarge;
      }

      &.primary {
        background: $primary-color;

        &:hover {
          background: $secondary-color;
        }
      }

      &.secondary {
        border: 1px solid #e7edef;
        background: white;
        color: $primary-color;

        &:hover {
          background: $quaternary-color;
        }
      }
    }

    .radio-button {
      label {
        position: relative;
        padding: 0 0 0 23px;
        cursor: pointer;

        &:before {
          position: absolute;
          top: 2px;
          left: 0;
          display: inline-block;
          width: 17px;
          height: 17px;
          border: 1px solid #e4ebed;
          background-color: white;
          content: '';
          cursor: pointer;
        }

        &:after {
          position: absolute;
          top: 1px;
          left: 0;
          display: inline-block;
          width: 17px;
          height: 17px;
          color: $secondary-color;
          font-family: Checkbox;
          font-size: 14px;
          font-weight: 300;
          line-height: 17px;
          text-align: center;
          transition:
            border 0.1s ease,
            opacity 0.1s ease,
            transform 0.1s ease,
            box-shadow 0.1s ease;
        }
      }

      input[type='radio'] {
        width: 0;
        height: 0;
        visibility: hidden;

        &:checked + label[for] {
          &:before {
            background: #e8f5fd;
          }

          &:after {
            content: '\e800';
          }
        }
      }
    }

    .radio-group,
    .checkbox-group {
      padding: 0;
      margin: 5px 0;
    }

    .checkbox {
      padding: 0;

      label {
        padding-left: 23px;
        color: $primary-color;

        &:before {
          border-color: #e4ebed;
          box-shadow: none;
        }
      }

      &.checked label {
        &:before {
          background-color: #e8f5fd;
        }

        &:after {
          color: $secondary-color;
        }
      }
    }

    input,
    textarea {
      padding: 20px 22px;
      border: 1px solid #e4ebed;
      border-radius: 10px;
      margin-top: 10px;
      color: $primary-color;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight::before {
      border-right-color: $secondary-color;
    }

    .DayPickerKeyboardShortcuts_showSpan__bottomRight {
      right: 7px;
      bottom: 5px;
      font-weight: 500;
    }

    .label-file-widget-input {
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 5px;
      margin: 0;
      background: $primary-color;
      color: white;
      opacity: 1;
      text-wrap: nowrap;
      transition: filter 0.2s ease;
      transition:
        border-color 0.2s ease,
        background 0.2s ease,
        color 0.2s ease;
    }

    .file-widget-dropzone {
      display: flex;
      padding: 20px 22px;
      padding: 10px 10px 10px 23px;
      border: 1px solid #e4ebed;
      border-radius: 10px;
      margin-top: 15px;
      color: $primary-color;
      gap: 20px;

      &:hover .label-file-widget-input {
        background: $secondary-color;
      }
    }

    .dropzone-placeholder {
      padding: 0;
      border: none;
      margin: 0;

      .dropzone-text {
        padding: 0.5rem 0;
        font-weight: 400;
        text-align: left;
      }
    }

    .field-file-name:empty {
      display: none;
    }

    .field {
      margin-bottom: 36px;

      .wrapper {
        display: block;
        min-height: unset;
        border: none;
        margin: 0;

        > label {
          color: $primary-color;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.5;
        }
      }

      &.required {
        .wrapper {
          .ui.label:after,
          > label[for]:after {
            width: unset;
            height: unset;
            border-radius: unset;
            margin-left: 0.2em;
            background: unset;
            content: '\20F0';
            font-size: 0.7em;
          }
        }

        &:has(input:not(:placeholder-shown):user-invalid) {
          input {
            border-color: #df3032;
          }

          label[for],
          .help {
            color: #df3032;
          }
        }
      }

      .help {
        padding-bottom: 0;
        border: none;
        color: $primary-color;
      }

      .static-text {
        margin: 0;
      }
    }
  }
}
