.context-navigation {
  padding: 0 2rem;
  margin-top: 48px;

  .context-navigation-header {
    a {
      display: flex;
      align-items: center;
      padding: 24px 0;
      border-bottom: 1px rgba(#0c3543, 0.15) solid;
      color: #0c3543;
      font-size: 21px;
      font-weight: 500;

      &::before {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        justify-content: center;
        border-radius: 999px;
        margin-right: 24px;
        background: rgba(#1d9bf0, 0.1);
        color: #1d9bf0;
        content: '<';
      }
    }
  }

  .list {
    padding-top: 12px;
    margin: 0;

    .item .content a {
      display: block;

      img {
        display: none;
      }
      margin: 12px 0;
      color: #0c3543;
      font-size: 18px;
      font-weight: 400;

      &.active {
        padding-left: 16px;
        border-left: 2px solid #1d9bf0;
        font-weight: 700;
      }
    }
  }

  .ui.list .list > .item > .content > .list,
  .ui.list > .item > .content > .list {
    padding-top: 1px;
    padding-left: 18px;
  }
}
