#footer {
  margin-top: $spacing-block;
}

#footer .footer {
  padding-bottom: 48px;
  border-top: 2px #fff solid;
  background: #edf4f6;
  color: #0c3543;
  font-size: 14px;
  text-align: left;

  .footer__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer__branding {
      display: flex;
      width: 33.33333%;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 64px;

      @media screen and (max-width: 940px) {
        width: 100%;
      }

      h2 {
        margin-bottom: 24px;
        color: #1a6f8c;
        font-size: 26px;
        font-weight: 300;
        line-height: 1;
        text-transform: uppercase;

        b {
          font-weight: 500;
        }
      }

      p {
        color: #234855;
      }

      .footer__socials {
        margin: 28px 0 48px;

        h4 {
          margin-bottom: 8px;
          font-size: 12px;
          text-transform: uppercase;
        }

        div {
          display: flex;
          align-items: center;

          img {
            margin-right: 16px;
          }
        }
      }
    }

    .footer__sitemap {
      a,
      a:link {
        display: block;
        color: #234855;
        font-weight: 400;

        &:hover {
          text-decoration: underline;
        }
      }

      .footer__hr {
        height: 1px;
        margin: 24px 0;
        background: #d7e2e5;
      }

      .footer__content-links {
        display: flex;
        flex-direction: column;

        flex-wrap: wrap;
        justify-content: flex-start;

        h4 {
          margin-bottom: 24px;
          font-weight: 500;
        }

        .column:last-child h4 {
          margin-bottom: 0;
        }

        a {
          margin-bottom: 16px;

          &.subsection {
            display: none;
          }
        }
      }

      .footer__links {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        a {
          font-size: 13px;
          line-height: 2.2;
        }
      }
    }
  }

  @media only screen and (min-width: $computer-breakpoint) {
    .footer__container {
      flex-direction: row;

      .footer__branding .footer__socials {
        margin-bottom: 0;

        h4 {
          font-size: 14px;
        }
      }

      .footer__sitemap {
        .footer__content-links {
          flex-direction: row;
          justify-content: flex-end;
          justify-content: space-between;
          margin-bottom: 64px;

          > div {
            margin-bottom: 32px;

            &:not(:last-child) {
              margin-right: 48px;
            }
          }

          h4 {
            margin-bottom: 16px;
          }

          .column:last-child h4 {
            margin-bottom: 16px;
          }

          a.subsection {
            display: block;
          }
        }

        .footer__links {
          flex-direction: row;

          a {
            margin-left: 36px;
          }
        }
      }
    }
  }
}
