#page-document.cijfer {
  display: flex;
  max-width: var(--layout-container-width);
  margin: 40px auto 0;
  column-gap: 8%;

  .column.three.wide {
    min-width: 300px;
  }

  .cijfer-left {
    width: 25%;

    .to-summary {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(12, 53, 67, 0.1);
      column-gap: 10px;
      cursor: pointer;

      button {
        display: flex;
        width: 20px;
        height: 20px;
        align-items: center;
        border: none;
        border-radius: 100px;
        background-color: #edf4f6;

        img {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }

      p {
        color: #0c3543;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .cijfer-navigation {
      margin-top: 20px;

      p {
        font-size: 16px;
      }

      .selected {
        padding-left: 20px;
        border-left: 2px solid #1d9bf0;
        font-weight: 600;
      }
    }
  }

  .cijfer-right {
    width: 66%;

    .blocks-group-wrapper {
      padding: 0;
    }
  }

  @media screen and (max-width: 940px) {
    .column.three.wide {
      display: none;
    }

    .column.seven.wide {
      width: 100% !important;
    }
  }
}
