body:not(.is-authenticated.has-toolbar) {
  .header .q.container.layout {
    margin-top: 44px;
  }

  .header-wrapper .vlw-wrapper {
    position: fixed;
    z-index: 9999;
    top: 0;
    width: 100%;
    background: white;
  }
}

.header-wrapper {
  padding: 0 2rem;

  .vlw-wrapper {
    margin: 0 -2rem;
  }

  .tools-wrapper {
    position: relative;
    height: 44px;
  }
}

.tools-wrapper {
  padding: 0 !important;

  #requireHelp-wrapper {
    display: flex;
    flex-direction: row;
    text-transform: uppercase;

    .arrow-up {
      width: 0;
      height: 0;
      border-top: 43px solid #1a6f8c;
      border-right: 13px solid #1a6f8c;
      border-left: 13px solid transparent;
      margin-right: 20px;
    }

    #requireHelp {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 12px;
      margin-left: -30px;
      background-color: #1a6f8c;

      a {
        margin: 0 10px 0 0;
        color: white;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
        text-align: left;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  #myProfile {
    display: flex;
    flex-flow: nowrap;
    padding-top: 10px;
    margin-right: 15px;

    .citizenLogo {
      fill: #333;
    }

    span {
      display: inline-block;
      padding: 1.5px 0 0 0.8em;
      color: #333;
      font-size: 14px;
      font-weight: bold;
      line-height: 20.4px;
      text-wrap-mode: nowrap;
    }

    &:hover span {
      color: #0055cc;
      text-decoration: underline;
    }
  }

  #vlw-progressbar {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: 0;
    width: 0%;
    height: 3px;
    background: #1a6f8c;
    content: '';
  }
}

#headerVlaanderen {
  .fl-logo {
    position: relative;
    display: flex;
    height: 44px;
    flex-direction: row;
    align-items: center;

    &:hover {
      &:after {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 45px;
        height: 44px;
        background: #ffe615;
        clip-path: polygon(0 0, 29px 0, 100% 100%, 0 100%);
        content: '';
      }

      a {
        color: #0055cc !important;
        text-decoration: underline;
      }
    }
  }

  a:hover {
    color: #0055cc !important;
    text-decoration: underline;
  }
}
