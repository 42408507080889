// Quick hack 2024-05-15 Fred to get introduction and header block styling working
// 2024-09-04 Ingo: let introduction and documentDescription appear the same in view and edit mode

#page-edit,
#page-document {
  .block.introduction .block-container *,
  .documentDescription {
    color: $primary-color;
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
  }
}

#page-edit .documentDescription {
  max-width: unset !important;
  margin: 0 !important;
}

#page-document .documentDescription {
  padding-bottom: 25px;
}

#page-document .block.introduction {
  padding-bottom: 25px;
  margin-top: 0;

  .block-container {
    padding: 0 !important;
  }
}
