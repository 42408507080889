.event-session {
  padding: 60px 50px;
  margin-top: 60px;
  margin-bottom: 60px;
  background-color: #ecf4f6;

  h2 {
    display: inline-block;
    font-size: 26px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid #b5c4c9;
    margin-bottom: 36px !important;
  }

  .session-info {
    span {
      line-height: 1.4;
    }

    .session-left {
      display: table-row;
    }

    .session-timing {
      display: table-cell;
      width: 200px;
      padding-right: 30px;
      font-weight: 400;
    }

    .session-right span {
      display: block;
    }

    .session-item {
      display: table-cell;
      font-weight: 600;
    }

    .session-speaker {
      padding-left: 200px;
      font-size: 0.9rem;
      font-style: italic;
    }

    .session-text {
      padding-left: 200px;
    }

    @media screen and (max-width: 940px) {
      .session-left {
        display: block;
      }

      .session-timing {
        display: initial;
        width: auto;
        white-space: nowrap;
      }

      .session-item {
        display: initial;
      }

      .session-right span {
        padding-left: 0;
      }
    }
  }

  &:has(+ .event-session) {
    padding-bottom: 1rem;
    border-bottom: 0;
    margin-bottom: 0;
  }

  + .event-session {
    position: relative;
    padding-top: 0;
    border-top: none;
    margin-top: 0;

    h2 {
      margin-top: 30px;
    }
    @media screen and (max-width: 940px) {
      &:not(:has(h2)) .session-info {
        padding-top: 1rem;
        border-top: 1px solid rgba(#0c3543, 0.1);
      }
    }
  }
}
