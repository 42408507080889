#page-document.agenda {
  position: relative;
  max-width: var(--layout-container-width);
  margin: 40px auto 0;

  .agenda-flex {
    display: flex;
    padding: 0 1rem;
    column-gap: 8%;
  }

  .agenda-flex:has(.headerimage img.responsive) {
    padding-top: 440px;
  }

  .agenda-left {
    width: 66%;
    height: fit-content;

    .blocks-group-wrapper {
      padding: 0;

      .headerimage {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% + 8% + 25%);
        margin-left: -1rem;

        img {
          width: 100%;
          height: 400px;
          margin-bottom: 40px;
          object-fit: cover;
        }
      }

      h1 {
        margin-top: 0;
      }

      h2 {
        margin-bottom: 25px;
      }
    }
  }

  .agenda-right {
    width: 25%;
    min-width: 300px;
    padding-top: 30px;
    margin-top: 10px;

    .right-side-section {
      margin-bottom: 80px;

      h5 {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(12, 53, 67, 0.1);
        margin-bottom: 30px;
      }

      .practical-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        column-gap: 40px;

        p {
          margin: 0;
          font-size: 18px;
          line-height: 1.5;

          &.infoTitle {
            font-weight: 300;
            text-wrap: nowrap;
          }

          &.infoContent {
            font-weight: 500;
            text-align: right;
          }

          a {
            color: #1d9bf0;
            font-weight: 500;
            text-decoration: underline;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 940px) {
    .agenda-flex {
      display: block;
    }

    .agenda-left,
    .agenda-right {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1600px) {
  #page-document.agenda .agenda-left .blocks-group-wrapper .headerimage {
    width: 100%;
  }
}

.contenttype-event {
  .blocks-group-wrapper.light:has(.block.socials-block) {
    padding: 0 2rem;
    margin: 30px 0 0;
  }

  .block.socials-block {
    max-width: var(--layout-container-width);
    padding: 4rem 0;
    margin: 0 auto;
  }
}
