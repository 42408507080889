.tags {
  display: flex;
  margin: 8px 0;
  gap: 8px;

  &:not(&--vertical) {
    flex-wrap: wrap;
  }

  &--vertical {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
}

.tag {
  align-content: center;
  padding: 4px 16px;
  border-radius: 100px;
  background-color: #edf4f6;
  color: #0c3543;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
