@font-face {
  font-family: 'Flanders-art-sans';
  font-weight: 400;
  src: url('./fonts/FlandersArtSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Flanders-art-sans';
  font-weight: 500;
  src: url('./fonts/FlandersArtSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Flanders-art-sans';
  font-weight: 600;
  src: url('./fonts/FlandersArtSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Flanders-art-sans';
  font-weight: 300;
  src: url('./fonts/FlandersArtSans-Light.ttf') format('truetype');
}

* {
  font-family: 'Flanders-art-sans', sans-serif;
}

.blocks-form > fieldset {
  min-width: auto;
}

#page-edit,
#page-add {
  // padding: 0 48px;
  margin-top: 30px;
}

#page-document
  .blocks-group-wrapper
  .block.gridBlock.is--first--of--block-type.previous--has--same--backgroundColor {
  margin-top: 0;
}

#page-document .blocks-group-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 20px;

  p {
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 30.6px;
    }

    strong span {
      font-weight: 700;
    }
  }
}

#page-document .blocks-group-wrapper > .block {
  margin-bottom: 0;
}

#page-edit .block.slate .slate-editor {
  margin-right: 0;
  margin-left: 0;
}

@mixin dark-background {
  background-color: $dark-bg-color;
  background-image: linear-gradient(
      rgba($dark-bg-color, 0.1),
      rgba($dark-bg-color, 0.1)
    ),
    radial-gradient(
      63.29% 15.22% at 50% -2.62%,
      #1d4e3b 0%,
      rgba(5, 39, 44, 0) 100%
    ),
    url('../assets/grain-bg-dark.png'), url('../assets/footer_background.png');

  background-position: center;

  * {
    border-color: $dark-bg-border-color;
    color: #fff;
  }

  .teaser.teaser--solid * {
    border-color: $octonary-color;
    color: $primary-color;

    .teaser__read-more {
      border-color: $secondary-color;
    }

    svg {
      fill: $secondary-color !important;
    }
  }

  .tag {
    color: $primary-color;
  }
}
@mixin light-background {
  background: $septenary-color url('../assets/footer_background.png') center;

  * {
    color: $primary-color;

    svg {
      fill: $secondary-color !important;
    }
  }
}

#page-edit,
#page-add {
  fieldset > div > div {
    padding: 0 $horizontal-page-padding;
  }

  .block .block:not(.inner)::before {
    z-index: unset;
    pointer-events: none;
  }

  .has--backgroundColor--dark:not([role='presentation']) {
    @include dark-background();
    padding: 2rem $horizontal-page-padding;
  }

  .has--backgroundColor--light:not([role='presentation']) {
    @include light-background();
    padding: 2rem $horizontal-page-padding;
  }
}

#page-document,
#page-edit,
#page-add {
  .blocks-group-wrapper {
    padding: 0 $horizontal-page-padding;

    > * {
      max-width: var(--default-container-width) !important;
    }

    &.dark {
      @include dark-background();
    }

    &.light {
      @include light-background();
    }

    .block {
      max-width: var(--layout-container-width);
      margin-right: auto;
      margin-left: auto;
    }

    .block.listing,
    .block.gridBlock,
    .block.socials-block {
      padding: 4rem 0;
    }
  }
}

/* title block margins and correct size */
#page-document,
#page-edit,
#page-add {
  color: $primary-color;

  h1.documentFirstHeading,
  .block.inner.title h1.documentFirstHeading {
    margin-top: $spacing-block;
    font-size: 48px;
    font-weight: 500;
    letter-spacing: 0.69px;
    line-height: 1;

    span span {
      font-size: 48px;
    }
  }

  h2,
  h2 span {
    font-size: 30px;
    font-weight: 500;
    line-height: 1;
  }

  h3,
  h3 span {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
  }

  ol,
  ul {
    display: flow-root;
    padding-inline-start: 25px;
  }

  ul > ul,
  ul > ol,
  ol > ul,
  ol > ol {
    margin-bottom: 0;
  }

  .ui.table td {
    color: $primary-color;
  }

  @media only screen and (min-width: $computer-breakpoint) {
    h1.documentFirstHeading,
    .block.inner.title h1.documentFirstHeading {
      margin-top: 60px;
      font-size: 69px;
      font-weight: 500;
      letter-spacing: 0.69px;
      line-height: 110%;

      span span {
        font-size: 69px;
      }
    }

    h2,
    h2 span {
      font-size: 32px;
      font-weight: 500;
      line-height: 1;
    }

    h3,
    h3 span {
      font-size: 26px;
      font-weight: 500;
      line-height: 1;
    }
  }
}

button.ui.circular.button {
  &:not(.active) {
    border-color: $primary-color;
  }

  &.dark {
    background: $dark-bg-color;
  }

  &.light {
    background: $light-bg-color;
  }
}

#main .ui.container.tags {
  display: none;
}

/* Set default paragraph line height to 170% according to font specs in Figma */

#page-document > .blocks-group-wrapper > p,
#page-edit .block.inner.slate p {
  line-height: 170%;

  a:link,
  a:visited {
    text-decoration: none;
  }
}

.content-area {
  li,
  ol {
    font-weight: 400;
    line-height: 170%;
  }

  li > a,
  ol > a,
  p > a {
    color: #1d9bf0 !important;
    font-weight: 400;
    text-decoration: none;

    &:hover {
      text-decoration: underline !important;
    }
  }

  #page-edit .ui.container a.external,
  .a.container a.external,
  .q.container a.external,
  .ui.container a.external {
    &:after {
      margin-left: 0.4em;
      content: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='external-link-alt' class='svg-inline--fa fa-external-link-alt fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='11' height='11'%3E%3Cpath fill='%231d9bf0' d='M432 320h-32a16 16 0 00-16 16v112H64V128h144a16 16 0 0016-16V80a16 16 0 00-16-16H48a48 48 0 00-48 48v352a48 48 0 0048 48h352a48 48 0 0048-48V336a16 16 0 00-16-16zM488 0H360c-21.37 0-32.05 25.91-17 41l35.73 35.73L135 320.37a24 24 0 000 34L157.67 377a24 24 0 0034 0l243.61-243.68L471 169c15 15 41 4.5 41-17V24a24 24 0 00-24-24z'/%3E%3C/svg%3E") !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .three .wide {
    display: none;
  }

  .seven .wide {
    width: auto !important;
  }
}

@import 'components';
@import 'blocks';
