.tag-social {
  padding-bottom: 20px;

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(12, 53, 67, 0.1);

    @media only screen and (max-width: 480px) {
      display: block;
    }
  }

  .text {
    min-width: 3rem;
    margin: 0;
    color: #0c3543;
    font-size: 16px;
    line-height: 150%;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 10px;

    .tags {
      display: flex;
      gap: 10px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 10px;

    .text {
      margin-left: 0.5rem;
      text-align: right;
      white-space: nowrap;
    }

    .socials {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        padding: 0;
        border: none;
        border-radius: 5px;
        background-color: white;
        color: #0c3543;
        cursor: pointer;
        font-size: 16px;
        outline: none;
      }

      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  .bottomDate {
    padding-top: 8px;
    margin-top: 8px;
  }
}
