#page-document.service {
  max-width: var(--layout-container-width);
  margin: 40px auto 0;
  column-gap: 8%;

  .to-summary {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    border-bottom: 1px solid rgba(12, 53, 67, 0.1);
    margin: 0 40px;
    column-gap: 10px;
    cursor: pointer;

    button {
      display: flex;
      width: 20px;
      height: 20px;
      align-items: center;
      border: none;
      border-radius: 100px;
      background-color: #edf4f6;

      img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }

    p {
      color: #0c3543;
      font-size: 21px;
      font-weight: 500;
    }
  }

  .service-content {
    display: flex;
    column-gap: 8%;

    .service-left {
      width: 66%;
      height: fit-content;

      .blocks-group-wrapper {
        .documentFirstHeading,
        .documentDescription,
        .block.introduction,
        div.tag-social,
        div.tag-social + .block.__button {
          padding-right: 40px;
          padding-bottom: 25px;
          padding-left: 40px;
          margin-right: -40px;
          margin-bottom: 0;
          margin-left: -40px;
          background-color: #f8fbff;
        }

        div.tag-social {
          &:has(+ .block.__button) {
            margin-bottom: 0;
          }

          + .block.__button {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-top: -25px;
            margin-bottom: 60px;
          }
        }

        .documentFirstHeading {
          padding-top: 20px;
          margin-top: 40px;
        }
      }

      .blocks-group-wrapper div.tag-social {
        margin-bottom: 60px;
      }

      .service-right {
        width: 25%;
        min-width: 300px;

        p {
          width: fit-content;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba(29, 155, 240, 1);
          font-size: 25px;
          font-weight: 500;
        }

        .service-link {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          column-gap: 5px;
          cursor: pointer;

          img {
            height: 12px;
          }

          p {
            width: fit-content;
            padding: 0;
            border-bottom: 1px solid rgba(29, 155, 240, 0.3);
            color: #1d9bf0;
            font-size: 20px;
            font-weight: 300;
          }
        }
      }
    }
  }

  @media screen and (max-width: 940px) {
    .to-summary {
      display: none;
    }

    .service-content {
      display: block;

      .service-left,
      .service-right {
        width: 100%;
      }
    }
  }
}
