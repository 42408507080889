.breadcrumbs {
  padding: 0 2rem !important;
  background-color: transparent !important;

  .section:active .section,
  a,
  .active,
  a:active {
    color: #0c3543 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 200 !important;
    letter-spacing: 0.32px;
    line-height: 26px; /* 162.5% */
    text-decoration: none;
  }
}

#main .breadcrumbs .breadcrumb .home:hover svg > path {
  fill: #1d9bf0;
}

@media screen and (min-width: 768px) {
  body.section-feiten-cijfers #main .breadcrumbs .breadcrumb .home,
  body.section-beleid #main .breadcrumbs .breadcrumb .home {
    position: relative;
    z-index: 1;
    margin-right: 0;

    &::after {
      position: absolute;
      top: 2px;
      right: -9px;
      border-top: 14.5px solid transparent;
      border-bottom: 14.5px solid transparent;
      border-left: 10px solid #0c3543;
      content: '';
    }

    &:hover::after {
      border-left-color: #1d9bf0;
    }
  }

  body.section-feiten-cijfers .breadcrumb,
  body.section-beleid .breadcrumb {
    svg:nth-child(2) {
      display: none;

      + a,
      + div {
        padding-right: 0.75rem;
        padding-left: 1.5rem;
        border-radius: 0 5px 5px 0;
        margin-left: -5px !important;
        background: #f8fbff;
        color: #0c3543 !important;
        font-weight: bold !important;
        text-decoration: none;
      }
    }

    .section {
      padding-top: calc((31px - 1em) / 2);
      padding-bottom: calc((31px - 1em) / 2);
      margin-top: -0.2em !important;
      color: #0c3543;
      margin-inline: 0.5rem !important;
    }
  }
}

#main .breadcrumb .section {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#main .breadcrumb {
  .breadcrumb-separator {
    margin-inline-end: 4px;
    margin-inline-start: 4px;
  }

  a.section {
    color: #0c3543 !important;

    &:hover {
      color: #1d9bf0 !important;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 767px) {
  .home {
    svg {
      display: none;
    }

    &:before {
      display: inline-block;
      min-width: 1rem;
      height: 1rem;
      border-radius: 50%;
      margin-bottom: -0.2rem;
      background: rgba(29, 155, 240, 0.1);
      background-image: url('../../../../theme/components/arrow_right.svg');
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      transform: rotate(180deg);
    }

    &:after {
      margin-left: 0.4em;
      color: #0c3543;
      content: 'Home';
    }

    &:hover:after {
      color: #1d9bf0;
    }
  }

  #main .breadcrumb {
    > * {
      display: none;
    }

    svg:nth-child(2) {
      min-width: 1rem;
      height: 1rem;
      padding: 0.2em;
      border-radius: 50%;
      margin-right: 0.25em;
      background: rgba(29, 155, 240, 0.1);
      transform: rotate(180deg);

      &:not(:nth-last-child(2)) {
        display: block;
      }
    }

    :nth-last-child(3) {
      display: block;
      background: transparent !important;
      font-weight: 500 !important;
    }
  }

  a {
    text-decoration-color: #0c3543;

    &:hover {
      text-decoration-color: #1d9bf0;
    }
  }
}
