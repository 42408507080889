.headline {
  margin-bottom: 16px;
  letter-spacing: 0.5px;
}

.mainArticleImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.wat-is-er-nieuw-block {
  a {
    color: $primary-color;
  }

  .summary-spotlight-body {
    padding: 20px 15px 0 15px;
  }

  .tag-social {
    padding-bottom: 0;

    .flex {
      padding-bottom: 0;
      border: none;
    }
  }

  button.button {
    box-shadow: 0 0 40px #e0fff1;

    &:hover {
      box-shadow: none;
    }
  }

  .list-item {
    &:not(:last-of-type) {
      border-bottom: 1px solid #d7e2e5;
    }

    &:last-of-type {
      margin-bottom: 40px;
    }

    .newsItem-date {
      font-size: 0.75rem;
    }

    .listing-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 10px;

      h3 {
        font-size: 1.1111rem !important;
        line-height: 1.2 !important;
      }

      svg {
        width: 8px;
        height: 13px;
        margin-top: 7px;
      }
    }
  }

  &.big-screen {
    display: flex;
    flex-direction: row;
    column-gap: 40px;

    .summary-spotlight-body {
      padding: 20px 40px 0 40px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
}

.summary-container {
  .list-item {
    padding: 15px 20px;

    .listing-body {
      h3 {
        width: calc(100% - 33px);
      }

      svg {
        width: 8px;
        height: 13px;
        margin-bottom: 26px;
      }
    }
  }

  &.big-screen {
    display: flex;
    width: 50%;
    min-width: 350px;
    flex-direction: column;
    justify-content: space-between;

    button.button {
      margin-left: 40px;
    }

    .list-item {
      padding: 20px 40px;
    }
  }
}

#page-add,
#page-edit,
#page-document {
  .summary-container button.button {
    img {
      width: initial !important;
    }

    span {
      font-size: 20px;
    }
  }
}

#page-document .blocks-group-wrapper > .block.whats-new .block-header {
  padding-left: 5px;
  margin-bottom: 20px;

  .headline {
    padding-bottom: 18px;
  }
}
