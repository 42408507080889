.header-wrapper .header {
  display: block;
  width: 100%;
  max-width: unset;
  margin: 0;
}

.logo {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 480px) {
  .header-wrapper .header .logo-nav-wrapper {
    display: block;
  }
}

.navigation {
  .item:active::before {
    position: absolute;
    bottom: -49px;
    width: 100%;
    border-bottom: 5px solid #1d9bf0;
    content: '';
  }
}

#subNav {
  display: flex;
  flex-wrap: wrap;
  gap: 43px;
  list-style-type: none;

  a {
    color: #0c3543;
    font-family: Flanders-Art-Sans;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 23px;
    text-align: left;
  }
}

.header__branding {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    margin-bottom: 24px;
    color: #1a6f8c;
    font-size: 26px;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;

    b {
      font-weight: 500;
    }
  }
}
