.arrawMore {
  position: absolute;
  top: 48.93%;
  right: 21.43%;
  bottom: 20.35%;
  left: 14.29%;
  /* Vector */
  border: 1.7px solid #0c3543;
}

.hamburger-wrapper .hamburger {
  .hamburger-inner:before,
  .hamburger-inner:after,
  &:before,
  &:after {
    height: 3px;
    border-radius: 999px;
    background: $tertiary-color;
  }
}

#navigation.navigation {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  margin-top: 20px;

  .item::before {
    bottom: -12px;
  }

  .item.active::before,
  .item:hover::before {
    position: absolute;
    width: 100%;
    border-bottom: 5px solid #1d9bf0;
    content: ' ';
  }

  .desktop-menu {
    li {
      padding: 0 30px;
    }

    li:first-child {
      padding-left: 0;
    }

    li {
      display: flex;
      height: fit-content;
      align-items: center;
      color: #0c3543;
      font-weight: 500;
      line-height: 23px;

      button {
        padding: 5px 0;
        border: none;
        margin: 0;
        color: #0c3543;
        font-family: Flanders-Art-Sans;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.01em;
        line-height: 23px;
        text-align: left;

        .item:hover::before {
          border-bottom-color: red !important;
        }
      }

      .submenu-wrapper {
        top: 56px;
      }

      .submenu {
        padding-top: 3rem;
        background-color: #f8fbff;
      }

      .submenu-inner {
        display: flex;
        max-width: var(--layout-container-width);
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: auto;

        ul {
          display: flex;
          width: 50%;
          justify-content: flex-end;

          li {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-end;
            padding: 0;
          }
        }

        .headerTitleBlock {
          display: flex;
          width: 50%;
          flex-direction: column;
          padding-right: 10rem;

          h2 {
            margin: 0 0 1.5rem 0;
            color: #0c3543;
            font-size: 43px;
            font-weight: 500;
          }

          a.button {
            align-self: flex-start;
            font-size: 18px;
          }

          p {
            font-weight: 400;
            line-height: 30px;
          }
        }

        .navItemsBlock {
          width: 50%;
          justify-content: flex-start;
          gap: 20px 40px;
        }

        .subitem-wrapper {
          padding: 10px 0;
          border-right: none;
          border-left: none;
          margin: 0;

          .pageDescription {
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0.01em;
            line-height: 23px;
            text-align: left;
          }

          .subitem-header {
            display: flex;
            width: 100%;
            align-items: flex-start;
            justify-content: space-between;
            padding: 0 0 0.5rem 0;
            border-bottom: #d4dadd 1px solid;
            margin: 0 0 0.5rem 0;

            .sub-submenu {
              display: flex;
              width: 100%;
              align-items: flex-start;
              justify-content: space-between;

              svg {
                width: fit-content;
                margin: 0;
              }

              * {
                width: 100%;
              }
            }

            p {
              margin-bottom: 0;
              color: #0c3543;
              font-size: 26px;
              font-weight: 500;
              line-height: 31px;
            }
          }
        }
      }

      svg {
        margin-left: 8px;
      }

      @media only screen and (min-width: $large-monitor-breakpoint) {
        .item::before {
          bottom: -35px;
        }

        .submenu-wrapper {
          top: 42px;
        }
      }
    }

    li:not(:last-child) {
      border-right: #cbd2da 1px solid;
    }
  }
}

@media only screen and (min-width: 1441px) {
  .ui.grid > .row > [class*='six wide large screen'].column,
  .ui.grid > .column.row > [class*='six wide large screen'].column,
  .ui.grid > [class*='six wide large screen'].column,
  .ui.column.grid > [class*='six wide large screen'].column {
    width: calc(50% - 25px) !important;
  }
}
