.ui.form .searchbox.field {
  width: 400px;
  padding: 0 10px;
  border: 1px #d8e3e6 solid;
  border-radius: 10px;
  background: white;

  svg.icon {
    flex-shrink: 0;
    margin: auto 10px auto 5px;
    fill: rgb(216, 227, 230) !important;
  }

  .input {
    font-size: 16px;
  }

  button {
    display: flex;
    height: fit-content;
    padding: 9px 19px;
    border-radius: 5px;
    margin: auto;
    background-color: #013138;
    color: white;
    font-size: 16px;
    font-weight: 300;
  }

  @media only screen and (min-width: $computer-breakpoint) and (max-width: 1300px) {
    width: 130px;

    button {
      display: none;
    }

    &:focus-within {
      position: absolute;
      z-index: 999;
      top: 0;
      right: 0;
      width: 400px;
      box-shadow: rgba(1, 49, 56, 0.05) 0 14px 44px;

      button {
        display: flex;
      }
    }
  }
}
