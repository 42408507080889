.publication-teaser {
  padding: 0 40px 0 0;
  background-color: #f8fbff;

  .publication-image {
    width: 100%;
  }

  button {
    margin: auto;
    margin-top: 20px;

    span {
      display: flex;
      align-items: center;
      column-gap: 15px;
    }
  }
}

.column:has(.teaser.publication-teaser) {
  min-width: 250px;
}
