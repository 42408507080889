@media print {
  body {
    --print-text-color: #333;
    --print-text-size: 10pt;
    --print-h1-size: 24pt;
    --print-h2-size: 16pt;
    --print-h3-size: 14pt;
    --print-description-size: 1Zpt;
    --print-text-line-height: 1.4;
  }

  // Exclude blocks from print
  .header-wrapper .vlw-wrapper,
  .breadcrumbs,
  .tag-social,
  .blocks-group-wrapper:has(.block.socials-block),
  .tags,
  .summary-container .list-item .listing-body svg,
  .block.gridBlock .teaser.data-teaser .headline svg,
  .block.gridBlock .teaser.card-teaser .headline svg,
  .block.Document .teaser.card-teaser .headline svg,
  .block.search .teaser.data-teaser .headline svg,
  .block.search .listing-item--publication .read-more-link svg,
  .block.search .listing-item--publication > a:after,
  .teaser.standalone-teaser
    .teaser-item
    .teaser__content
    .teaser__title-underline,
  #page-document.service .to-summary,
  #page-document.project .project-left .blocks-group-wrapper .headerimage,
  #page-document .blocks-group-wrapper > .block.image.align.center,
  #view #page-document .blocks-group-wrapper .teaser.standalone-teaser:empty,
  #view
    #page-document
    .blocks-group-wrapper
    .teaser.standalone-teaser
    .teaser-item
    > div:empty,
  #view
    #page-document
    .blocks-group-wrapper
    .teaser.standalone-teaser
    .teaser-item
    .button
    img,
  #view
    #page-document
    .block.gridBlock
    .teaser.publication-teaser
    .teaser__links
    button,
  #view
    #page-document
    .block.gridBlock
    .teaser.publication-teaser
    .read-more-link,
  #view
    #page-document
    .block.gridBlock
    .teaser.publication-teaser
    .read-more-link
    svg,
  #view #page-document .block.search .column.search__facet-column,
  #view #page-document .block.search .column .filter-listing,
  #view #page-document .block.search .column .search-results-count-sort,
  #view #page-document.nieuwsbericht .blocks-group-wrapper .headerimage,
  #view #page-document.nieuwsbericht .blocks-group-wrapper .tag-social .right,
  #view #page-document.nieuwsbericht .blocks-group-wrapper .tag-social .tags,
  #view #page-document .blocks-group-wrapper .data-teaser .read-more-link,
  #view #page-document .teaser__headline-chevron,
  #footer {
    display: none;
  }

  // Header
  .header-wrapper {
    padding: 0;

    .header .logo-nav-wrapper {
      padding: 0;
    }
  }

  .logo {
    margin: 0;
  }

  // Restyle components for print
  #view {
    #page-document {
      margin: 0 auto;

      .blocks-group-wrapper {
        padding: 0;

        > p,
        > ul,
        > ol {
          margin-inline: auto;
        }

        &.dark {
          background: none;

          * {
            color: var(--print-text-color);
          }
        }

        .block.gridBlock {
          padding: 1em 0;

          .column {
            display: block;
            width: 100% !important;
            padding: 0;
            margin: 0;

            .teaser__content {
              padding-right: 0;
              padding-left: 32px;
            }

            .card-teaser .teaser__content {
              padding-top: 0;
              padding-left: 0;
              border: none;
              background: none;
            }
          }
        }

        .block.gridBlock .carousel__pages,
        .block.search .carousel .carousel__pages,
        .block.listing .carousel .carousel__pages {
          display: none;
        }

        // Teaser
        .column:not(:last-of-type) .teaser-item {
          border-bottom: 1px solid #d7e2e5;
        }

        .teaser-item {
          display: flex;
          flex-direction: row;
          padding-bottom: 1em;
          margin-bottom: 1.5em;

          .image-wrapper {
            max-width: 5cm;
            margin-bottom: 0;
            float: left;
          }
        }

        .carousel .teaser-item {
          display: block;

          .teaser__content {
            width: calc(100% - 5cm);
          }
        }

        .teaser {
          &.spotlight-teaser .headline {
            display: initial;
          }

          &.minimal-teaser {
            .headline {
              padding-bottom: 1em;
              border-bottom: 1px solid #d7e2e5;
              margin-bottom: 1em;
            }

            .teaser-item {
              padding: 0;
              border: none;
            }

            .image-wrapper {
              margin-bottom: 0;
            }
          }

          &.standalone-teaser {
            padding: 1em 0;

            .teaser-item {
              display: inline-block;
              margin-bottom: 0;

              .teaser__content {
                width: calc(100% - 5cm) !important;
              }
            }
          }
        }

        .project-teaser .teaser-item {
          display: block;
        }

        .data-teaser,
        .card-teaser {
          .teaser-item {
            display: block;

            .headline {
              padding-bottom: 0;
              border: none;
            }
          }
        }

        .block.gridBlock .column .data-teaser .teaser__content {
          padding-left: 0;
        }

        .data-teaser .teaser-item .teaser__content {
          padding-top: 0;
          border: none;
          background: none;
        }

        & > .block .block-header {
          margin-bottom: 1em;
        }

        & > p,
        & > ul,
        & > ol {
          margin-inline: 0;
        }
      } // BLOCKS-GROUP-WRAPPER

      // Regular content
      li,
      p,
      p span,
      a,
      button.button {
        color: var(--print-text-color) !important;
        font-size: var(--print-text-size);
        line-height: var(--print-text-line-height);
      }

      p.documentDescription,
      .indicator-header .indicator-header__text p {
        padding: 0;
        background: none;
        color: var(--print-text-color);
        font-size: var(--print-description-size);
        margin-inline: auto;
        text-shadow: none;
      }

      a::after,
      a.external::after {
        margin-right: 0.25em;
        margin-left: 0.25em;
        content: attr(href) !important;
        overflow-wrap: break-word;
        text-decoration: underline;
      }

      h1.documentFirstHeading,
      .indicator-header .indicator-header__text h1 {
        margin-top: 0;
        color: var(--print-text-color);
        font-size: var(--print-h1-size);
        text-shadow: none;
      }

      h2 span,
      .blocks-group-wrapper > .block .block-header h2.headline {
        font-size: var(--print-h2-size);
      }

      h3 {
        font-size: var(--print-h3-size);
      }

      button.button,
      a.button {
        width: fit-content;
        padding: 5pt 10pt;
        border: 1px solid var(--print-text-color);
        box-shadow: none;
      }

      .button.container {
        margin: 14pt auto;
      }

      // Show message iframe and video
      .block.video,
      .block.iframe {
        border: 1px solid;
        margin-top: 14pt;
      }

      .block.video:before {
        padding: 5pt;
        content: 'Video';
      }

      .block.iframe:before {
        padding: 5pt;
        content: 'Partner content';
      }

      // Macro-indicator
      .indicator-header__container {
        padding-top: 0;
        padding-bottom: 1em;
        background: none !important;
      }

      .indicator-header {
        .indicator-header__indicators {
          padding-top: 0;
          background: none;
        }

        .indicator-header__indicators-list {
          display: block;
          width: var(--layout-container-width);
          margin: 0 auto;
        }

        .indicator {
          width: 50%;
          float: left;

          .indicator__name {
            color: var(--print-text-color);
            font-size: var(--print-h3-size);
            font-weight: 500;
            opacity: 1;
            text-align: left;
          }

          .indicator__content > div {
            width: fit-content;
            padding-left: 1em;
          }

          p {
            color: var(--print-text-color);
          }

          &.indicator--vertical .indicator__content img,
          .indicator__icon {
            border: 5px solid var(--print-text-color);
            background: var(--print-text-color);
          }
        }

        .indicator__content,
        .indicator__status,
        .indicator__future-status {
          flex-direction: row;
          justify-content: left;
        }

        .indicator__future-status span {
          margin-right: 0.25em;
        }

        .indicator__status-color {
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
        }
      }

      // Snel naar
      .snel-naar-block {
        padding-top: 0;
        border: none;
        border-bottom: 1px solid #d7e2e5;
        margin-top: 0;
        margin-bottom: 1em;
        overflow-wrap: break-word;
        padding-inline: 0;

        svg {
          display: none;
        }

        .snbTitle {
          font-size: var(--print-h3-size);
          letter-spacing: 0.5px;
        }
      }

      // Accordion
      .accordion-block {
        .ui.styled.accordion {
          border-bottom: none !important;

          .title.accordion-title:before {
            content: none;
          }
        }

        .align-arrow-right {
          flex-direction: row;
        }
      }

      // News
      &.nieuwsbericht .blocks-group-wrapper {
        > :not(.headerimage),
        h1,
        h2,
        .tag-social {
          width: 100%;
        }

        .tag-social .left .text {
          font-size: 0.9em;
          font-weight: 400;
        }
      }

      // Press
      .vmm-article-listing {
        .column.vmm-listing__item {
          margin-bottom: 1em;

          > a {
            padding-bottom: 1em;
          }
        }

        .vmm-listing__items .vmm-listing__content {
          padding: 0;
        }
      }

      // Project
      &.project {
        .project-flex {
          display: block;
          padding-top: 0;
          padding-inline: 0;

          .project-left,
          .project-right {
            width: 100%;
          }

          .project-right .right-side-section {
            .partners {
              display: block;

              img {
                max-width: 5cm !important;
              }
            }
          }
        }
      }

      // What's new
      .wat-is-er-nieuw-block .list-item {
        padding: 15px 0;

        &:first-of-type {
          border-top: 1px solid #d7e2e5;
          margin-top: 15px;
        }
      }

      // Spotlight
      .block.listing {
        padding: 0;

        .summary-spotlight-body {
          padding: 15px 0 0;
        }
      }

      // Services
      &.service .service-content {
        display: block;

        .service-left {
          width: 100%;

          .blocks-group-wrapper {
            .documentFirstHeading {
              margin: 0;
              padding-inline: 0;
            }

            .documentDescription {
              margin: 0;
            }

            div.tag-social + .block.__button {
              padding: 0;
              margin: 0 0 1em 0;
              margin-top: 0;
              margin-bottom: 1em;
              background: none;
            }
          }
        }
      }

      // Publication
      &.publication {
        flex-direction: column;

        .publication-left,
        .publication-right {
          width: 100%;
        }

        .publication-right {
          .publication-teaser {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0;
            gap: 15px;

            a {
              font-size: var(--print-text-size);
            }

            button {
              margin: 1em 0;
            }
          }

          .publication-image {
            max-width: 5cm;
          }
        }
      }

      .block.gridBlock .teaser.publication-teaser {
        padding: 0;

        .teaser-item:after {
          content: unset !important;
        }

        .image-wrapper {
          max-width: unset;
        }
      }

      // Agenda
      &.agenda {
        .agenda-flex {
          display: block;
          padding: 0;
        }

        .agenda-left,
        .agenda-right {
          width: 100%;

          .agenda-right .right-side-section {
            .practical-info {
              p,
              a {
                font-size: var(--print-text-size);
              }
            }

            h5 {
              border: none;
              margin: 0;
              font-size: var(--print-h3-size);
            }
          }
        }
      }

      // Event
      .listing-item.listing-item--event .listing-body__date {
        padding: 0;
        border: none;
      }

      // Other
      p + ol,
      p + ul {
        margin-top: -15px;
      }
    } // PAGE-DOCUMENT

    // Questions
    .questions {
      display: block;
      padding: 1em;
      margin-top: 1em;

      .contact-link {
        margin: 0;

        a:after {
          content: none !important;
        }
      }

      .questions-right .contact-opening-hours,
      .questions-text {
        font-size: var(--print-text-size);
      }

      h2 {
        font-size: var(--print-h2-size);
      }

      h5 {
        font-size: var(--print-h3-size);
      }
    }

    // Graphs
    .block-container.graph > div {
      width: 100% !important;
      max-width: 18cm;
    }

    // Search
    .block.search .column,
    .block.listing .column {
      flex-direction: column;
      padding: 0 15px;
    }

    .block.search .vmm-listing__item,
    .block.listing .vmm-listing__item {
      width: 100% !important;
    }

    // Pagination
    .pagination-wrapper {
      .total {
        margin: 1em;
        font-size: 10pt;
      }

      .ui.pagination.menu .item {
        box-shadow: none;
      }
    }

    // Event
    .block.search .listing-item.listing-item--event {
      margin-bottom: 1em;
    }
  } // VIEW

  // Icon glossary term marker
  .glossarytooltip:after {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  // Tables
  .ui.table {
    font-size: var(--print-text-size);

    th {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
  }

  // indicator
  body.contenttype-cijfer.subtype-indicator
    .accordion-block
    h3:has(
      span.communicatie,
      span.doelstellingen,
      span.effecten-op-het-ecosysteem,
      span.evolutie,
      span.gevolgen,
      span.gezondheidseffect,
      span.hoe-pakken-we-dit-aan,
      span.meer-informatie,
      span.toestand
    ) {
    padding: 1em 0 1em 51px !important;
  }

  body.contenttype-cijfer.subtype-indicator .accordion-block h3 > span:before {
    top: 15px;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  // Images
  .block.image figure.center {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  // Events
  .listing-item.listing-item--event > a {
    display: block;
    padding-bottom: 1em;
    border-bottom: 1px solid #d7e2e5;
  }

  // Publication
  .block.search .listing-item--publication .read-more-link {
    padding: 0;
    border: none;
  }
}
