body.has-toolbar:not(.has-sidebar):not(.has-sidebar-collapsed) .ui.container,
body.has-toolbar-collapsed:not(.has-sidebar):not(.has-sidebar-collapsed)
  .ui.container {
  width: unset !important;
  max-width: var(--layout-container-width) !important;
  margin: 40px auto 0;
  color: $primary-color;
}

.contenttype-glossary,
.contenttype-term {
  h1.documentFirstHeading {
    margin: 60px auto 20px;
    color: $primary-color;
    font-size: 69px;
    font-weight: 500;
  }

  p.documentDescription {
    padding-bottom: 25px;
    color: $primary-color;
    font-size: 24px;
    font-weight: 300;
    line-height: 170%;
  }
}

#content-core.glossary {
  max-width: 820px;
  margin: 0 auto;
  margin-bottom: 10rem;

  h2.letter {
    padding: 0;
    border-bottom: 1px solid #e7ebec;
    margin: 4rem 0 2rem;
    background: transparent;
    color: $primary-color;
    font-size: 144px;
    font-weight: 500;
    line-height: 1;
  }

  .term {
    margin-bottom: 30px;

    h3 a {
      color: $primary-color;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.5;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}

.contenttype-term {
  #content {
    max-width: 820px;
    margin: 0 auto;
    margin-bottom: 10rem;
  }

  a.item svg {
    border: none;
    border-radius: 5px;
    background: #0c3543;
    fill: white !important;
  }
}

.glossary-view .glossaryAlphabet {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 60px;
  padding-bottom: 1rem;

  .alphabetLetter {
    min-width: 1em;
    padding: 0 0 0.5rem 0;
    border-bottom: 2px solid transparent;
    text-align: center;

    &.unmatched {
      filter: opacity(1);
    }

    span {
      font-size: 24px;
      font-weight: 400;
    }

    &:hover {
      border-color: $secondary-color;

      span {
        font-weight: bold;
      }
    }
  }
}

.glossarytooltip {
  border-bottom: none;
  color: $secondary-color !important;
  white-space: nowrap;

  &:after {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-bottom: 0.3em;
    margin-left: 0.2em;
    background-image: url('../../assets/icon_questionmark.svg');
    content: '';
  }
}

.ui.popup.tooltip {
  max-width: 25rem;
  padding: 30px;
  border: none;
  border-radius: 5px;
  margin: 3px;
  box-shadow: 0 0 60px #ccc;

  &:before {
    width: 1rem;
    height: 1rem;
    margin: -0.2rem;
    box-shadow: none !important;
  }

  .header {
    padding-bottom: 10px;
    border-bottom: 1px solid #e7ebec;
    margin-bottom: 10px;
    color: $secondary-color;
    font-size: 18px;
    font-weight: 500;
  }

  .content * {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
  }

  @media screen and (max-width: 768px) {
    max-width: 15rem;
  }
}
