#page-document .blocks-group-wrapper .block.gridBlock,
#page-edit .block-editor-gridBlock .grid-items {
  .button.template-chooser-item {
    border-radius: 0;
    color: $primary-color;
  }

  &.has--backgroundColor--dark {
    .block-header h2.headline {
      border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
    }

    .button.remove-block-button svg {
      background-color: black;
    }
  }

  .grid,
  .blocks-form[role='presentation'] > fieldset > div {
    --column-gap: 20px;
    max-width: var(--default-container-width);
    flex-wrap: wrap;
    margin: 0 calc(-1 * var(--column-gap));

    .column:not(.row),
    > div {
      padding: 0 var(--column-gap);
      margin: 0 !important;
    }
  }

  @media only screen and (min-width: $computer-breakpoint) {
  }
}

.navigation-container {
  max-width: var(--layout-container-width);
  margin: 0 auto 0;
}

#page-add,
#page-edit,
#page-document {
  .block.gridBlock {
    .block-header:has(> a:nth-child(1):nth-last-child(1)) > a {
      display: block;
      width: 100%;
      text-align: right;

      &:after {
        content: none !important;
      }
    }

    .block.image figure:not(:has(h2)):after {
      display: none;
    }
  }

  .block .teaser a.external::after {
    content: none !important;
  }
}

.block.gridBlock .minimal-teaser .teaser__content {
  color: $primary-color;
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  @media screen and (max-width: 940px) {
    font-size: 24px;
    line-height: 1.3;
  }
}

.blocks-group-wrapper.light
  .block.gridBlock
  .minimal-teaser
  .teaser__content
  p {
  font-size: 18px;
  @media screen and (max-width: 940px) {
    font-size: 16px;
  }
}
