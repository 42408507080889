a.read-more-link {
  display: flex;
  flex-grow: 0;
  align-items: center;
  align-self: flex-start;
  padding-bottom: 8px;
  border-bottom: 1px solid $secondary-color;
  margin-top: 12px;
  font-weight: 500 !important;

  svg {
    margin-top: 4px;
    margin-right: -8px;
    margin-left: 8px;
    color: $secondary-color;
  }
}
