#footer .footer.support-footer {
  padding-top: 64px;
  padding-bottom: 48px;
  background: #f7fafb url('../../assets/footer_background.png') no-repeat center;

  > div.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      margin-bottom: 28px;
      color: #0c3543;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.4px;
    }

    ul {
      flex-direction: column;
      margin: 0 0 0 20px;

      li {
        padding: 0 0 15px 0;
        border: none;
        color: #0c3543;
        font-size: 17px;
        line-height: 31px;
        list-style: initial;
        list-style-position: initial;
        text-align: left;

        a {
          color: #0c3543;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (min-width: $computer-breakpoint) {
    padding-top: 105px;
    padding-bottom: 90px;

    > div.layout {
      flex-direction: row;

      h2 {
        width: 50%;
        font-size: 42px;
        white-space: nowrap;
      }

      ul {
        width: 50%;
      }
    }
  }
}
