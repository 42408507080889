#view,
#page-edit,
#page-add {
  .block-container.graph {
    padding: 35px 30px 15px 10px;
    border: 1px solid rgba(216, 227, 230, 0.7);
    margin: 40px auto;
    background: #f8fbff;

    .image-wrapper {
      padding-left: 20px;
    }

    p {
      padding: 0 32px 16px;
    }

    .graph-caption {
      padding: 0 0 0 20px;
      font-size: 0.8rem;
    }

    .graph-data-source {
      display: block;
      margin: 15px 0 0 20px;
      font-size: 0.8rem;
    }

    .staticTitle {
      margin-bottom: 15px;
      font-weight: 500;
      line-height: 1.2;
    }

    .staticFig {
      text-align: center;

      img.responsive {
        margin-left: 10px;
      }
    }
  }
}

.highcharts-data-label text {
  color: #0c3543 !important;
  font-size: 0.8rem !important;
}
