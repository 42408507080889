#view .warnings,
#page-edit .warnings {
  width: 100%;
  padding: 10px 16px;

  h5 {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    color: #fff;
    font-family: 'Flanders Art Sans';
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.36px;
    line-height: 23px;
    text-transform: uppercase;
  }

  > a,
  > a:hover {
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    color: #fff;
    font-size: 13px;
    font-weight: 300;
    line-height: 23px;
    text-decoration: underline;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  img {
    margin-right: 16px;
  }

  @media only screen and (min-width: $computer-width) {
    h5 {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.blocks-group-wrapper .card.indicatorBlock .indicatorText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
}
