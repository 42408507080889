.typography {
  $prefix: 'typography--';

  @mixin typo-size($variation, $size, $weight: 400, $transform: inherit) {
    &.typography--#{$variation} {
      font-size: $size;
      font-weight: $weight;
      text-transform: $transform;
    }
  }

  @mixin typo-color($variation, $color) {
    &.typography--#{$variation} {
      color: $color;
    }
  }
  @mixin typo-weight($variation) {
    &.typography--#{$variation} {
      font-weight: $variation;
    }
  }
  @mixin typo-lineheight($variation, $lineheight) {
    &.#{$prefix}lineheight-#{$variation} {
      line-height: $lineheight;
    }
  }

  @include typo-size(h1, 48px, 500);
  @include typo-size(h2, 30px, 500);
  @include typo-size(h3, 24px, 500);
  @include typo-size(h4, 18px, 500, $transform: uppercase);
  @include typo-size(h5, 18px, 500);

  @include typo-size(xl, 24px);
  @include typo-size(lg, 20px);
  @include typo-size(md, 16px);
  @include typo-size(sm, 14px);
  @include typo-size(xs, 12px);

  @include typo-color(primary, $primary-color);
  @include typo-color(secondary, $secondary-color);
  @include typo-color(tertiary, $tertiary-color);
  @include typo-color(senary, $senary-color);
  @include typo-color(dark, $primary-color);
  @include typo-color(light, #fff);

  @include typo-lineheight('normal', 1);
  @include typo-lineheight('intro', 36px);
  @include typo-lineheight('broodtekst', 30.6px);

  @media only screen and (min-width: $computer-breakpoint) {
    @include typo-size(h1, 69px, 500);
    @include typo-size(h2, 32px, 500);
    @include typo-size(h3, 26px, 500);
    @include typo-size(h4, 18px, 500, $transform: uppercase);
    @include typo-size(h5, 18px, 500);

    @include typo-size(xl, 24px);
    @include typo-size(lg, 21px);
    @include typo-size(md, 18px);
    @include typo-size(sm, 16px);
    @include typo-size(xs, 14px);
  }

  &a {
    text-decoration: underline;
  }

  &.typography--uppercase {
    text-transform: uppercase;
  }

  @include typo-weight(300);
  @include typo-weight(400);
  @include typo-weight(500);
}

#page-document .blocks-group-wrapper > .block,
#page-edit,
#page-edit .block-editor-listing .block.listing,
#page-add {
  .block-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
    color: $primary-color;

    a {
      display: none;
      opacity: 0.8;
      text-decoration: underline;
    }

    h2.headline {
      padding-bottom: 4px;
      border-bottom: $secondary-color 1px solid;

      margin: 0 !important;
      margin-bottom: 42px;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: -0.24px;
      line-height: 23px;
      opacity: 0.9;
    }

    @media only screen and (min-width: $computer-breakpoint) {
      h2.headline {
        font-size: 24px;
      }

      a {
        display: flex;
      }
    }
  }
}
