.block-editor-search .pagination-wrapper .ui.pagination.menu,
.block.listing .pagination-wrapper .ui.pagination.menu,
.block.search .pagination-wrapper .ui.pagination.menu,
.column > .block.listing .pagination-wrapper .ui.pagination.menu {
  display: flex;
  min-height: initial;
  margin: 30px 0;
  gap: 0.5rem;

  .item {
    display: inline-block;
    min-width: initial;
    padding: 15px 0.25em;
    border: none;
    border-bottom: 3px solid transparent;
    color: $primary-color; // #0c3543
    line-height: 1;
    margin-inline: 0.5em;

    &:hover {
      border-color: $secondary-color; // #1d9bf0
    }

    &.active {
      border-bottom: 3px solid #1d9bf0;
      background: transparent;
      color: $primary-color; // #0c3543
      font-weight: 700;
    }

    &[type='nextItem'] {
      padding: 15px 25px;
      border: none;
      border-radius: 99px;
      background: $primary-color; // #0c3543
      box-shadow: 0 0 40px #e0fff1; // #C1FFE4
      color: white;
      @media only screen and (min-width: 940px) {
        &::after {
          display: inline-block;
          min-width: 1em;
          border-radius: 50%;
          margin-left: 0.75em;
          background: #244956;
          content: '>';
        }
      }

      &:hover {
        background: #1d9bf0;
        box-shadow: 0px 0px 40px #cae8f7; // #A2DBF9
        &::after {
          background: #34a5f2;
        }
      }
    }

    &[type='prevItem'] {
      padding: 15px 25px;
      border: none;
      border-radius: 99px;
      background: $primary-color !important; // #0c3543
      box-shadow: 0 0 40px #e0fff1; // #C1FFE4
      color: white !important;
      @media only screen and (min-width: 940px) {
        &::before {
          position: relative;
          display: inline-block;
          min-width: 1em;
          border-radius: 50%;
          margin-right: 0.75em;
          background: #244956;
          content: '<';
        }
      }

      &:hover {
        background: $secondary-color !important; // #1d9bf0
        box-shadow: 0px 0px 40px #cae8f7; /* #A2DBF9 */
        &::before {
          background: #34a5f2 !important;
        }
      }
    }

    &.disabled {
      display: none;
    }
  }
}
@media only screen and (max-width: 848px) {
  .block.listing .pagination-wrapper,
  .column > .block.listing .pagination-wrapper,
  .block.search .pagination-wrapper,
  .block-editor-search .pagination-wrapper {
    &:has(.ui.pagination.menu.mobile-pagination) {
      margin-top: 60px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .carousel .carousel__pages {
    bottom: unset;
  }
}

.search-footer {
  .ui.pagination.menu .item {
    min-width: initial;
    padding-bottom: 5px;
    border-bottom: 3px solid transparent;
    margin-bottom: 10px;
    font-weight: 400;
    margin-inline: 0.5em;
    padding-inline: 0.25em;
  }

  .ui.pagination.menu .item:hover {
    border-bottom: 3px solid #1d9bf0;
  }

  .ui.pagination.menu .active.item {
    border-bottom: 3px solid #1d9bf0;
    font-weight: 700;
  }

  .ui.pagination.menu .item.icon {
    position: relative;
    min-width: 7em;
    padding: 15px 25px;
    border-radius: 999px;
    border-bottom: 0;
    margin-bottom: 0px;
    background: $primary-color !important; // #0c3543
    box-shadow: 0 0 40px #e0fff1; /* #C1FFE4 */
    color: white !important;
  }

  .ui.pagination.menu .item.icon:hover {
    border: none;
    background: $secondary-color !important; // #1d9bf0
    box-shadow: 0px 0px 40px #cae8f7; /* #A2DBF9 */
    font-weight: 400;
  }

  .ui.pagination.menu .item svg {
    border-radius: 50%;
    background: #244956;
  }

  .ui.pagination.menu .item:hover svg {
    background: #34a5f2;
  }

  .ui.pagination.menu .item[type='prevItem'] svg {
    margin-right: 0.75em;
  }

  .ui.pagination.menu .item[type='nextItem'] svg {
    margin-left: 4.5em;
  }

  .ui.pagination.menu .item[type='prevItem']::after {
    position: relative;
    display: block;
    content: 'Vorige';
    line-height: 28px;
  }

  .ui.pagination.menu .item[type='nextItem']::before {
    position: relative;
    display: block;
    content: 'Volgende';
    line-height: 28px;
  }

  .ui.pagination.menu .disabled.item {
    display: none;
  }
}
