#page-document.nieuwsbericht {
  max-width: var(--layout-container-width);
  margin: 0 auto 60px;

  .headerimage img {
    width: 100%;
    height: 400px;
    margin-bottom: 40px;
    object-fit: cover;
  }

  .blocks-group-wrapper {
    .headerimage {
      width: 100%;
      order: 0;
      padding-right: 0;
      padding-left: 0;
      margin-top: 30px;
    }

    h1 {
      margin-top: 0;
    }

    /* Set order for other children to ensure they follow the header image */
    > *:not(.headerimage) {
      display: block;
      width: 75%;
      order: 1;
      @media screen and (max-width: 940px) {
        width: 100%;
      }
    }

    .block.image.align.center .figure {
      max-width: unset;
    }
  }
}

.contenttype-news-item {
  .blocks-group-wrapper.light:has(.block.socials-block) {
    padding: 0 2rem;
    margin: 30px 0 0;
  }

  .block.socials-block {
    max-width: var(--layout-container-width);
    padding: 4rem 0;
    margin: 0 auto;
  }
}
