// Used in some semantic ui styling
html {
  --custom-main-font: 'Flanders-art-sans';
}

$page-font: 'Flanders-art-sans' !important;
$layout-container-width: '1600px';
$default-container-width: $layout-container-width;
$horizontal-page-padding: 2rem;

$tablet-breakpoint: 769px !default;
$computer-breakpoint: 941px !default;
$large-monitor-breakpoint: 1441px !default;

// Container widths

$layout-container-width: '1600px' !default;
$default-container-width: 1600px !default;
$narrow-container-width: 1100px !default;

// Colors
$primary-color: #0c3543; // font color, background dark blocks
$secondary-color: #1d9bf0; // button blue, links
$tertiary-color: #1a6f8c; // vmm blue
$quaternary-color: #edf4f6; // light grey background tags
$quinary-color: #f8fbff; // light blue
$senary-color: #013138;
$septenary-color: #f7fafb; // very light gray background light blocks
$octonary-color: #e2eaed; // light gray border data blocks solid

$dark-bg-color: #05272c;
$light-bg-color: $quaternary-color;
$light-blue-bg-color: $quinary-color;
$dark-bg-border-color: rgba(205, 205, 205, 0.2);

// Override volto light theme vertical spacing

// Spacing
$spacing-small: 10px !default;
$spacing-medium: 10px !default;
$spacing-large: 20px !default;
$spacing-xlarge: 20px !default;
$spacing-block: 60px !default;
