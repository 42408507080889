#page-document,
#page-edit,
#page-add {
  .block.search .ui.stackable.searchBlock-facets > .row > .column {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .block.search .searchBlock-facets.left-column-facets {
    .search__facet-column {
      display: flex;
      flex-direction: column;
      gap: 48px;
    }

    .facets {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 0;
      gap: 16px;

      .button.icon {
        svg {
          margin: -4px 0;
        }
      }

      .button.active {
        background: #d8e3e6;
      }

      .search-wrapper {
        width: auto;
        flex: 1;
        margin: 0;

        .search-input {
          display: flex;
          height: auto;
          border: 1px solid rgba(216, 227, 230, 0.7);
          border-radius: 10px;
          background: transparent;
          transition: border 0.2s ease;

          &:has(input:focus) {
            border: 1px solid rgba($primary-color, 0.3);
          }

          .ui.fluid.input {
            height: auto;
            flex: 1;
            background: transparent;

            input {
              padding: 20px;
              color: $primary-color;
              font-size: 18px;

              &::placeholder {
                color: $primary-color;
                opacity: 0.5;
              }
            }
          }

          &:has(.icon) .ui.fluid.input input {
            padding-left: 48px;
          }

          .icon {
            position: absolute;
            top: 50%;
            left: 16px;
            fill: $primary-color !important;
            opacity: 0.3;
            transform: translateY(-50%);
          }

          .search-input-actions {
            padding: 12px;
          }
        }
      }

      .facet {
        padding-top: 0 !important;
        padding-right: 16px;
        padding-bottom: 30px;
        margin: 0;

        h4.ui.header {
          color: $primary-color;
          font-size: 24px;
        }

        .checkbox-facet .entries {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .entry.sub {
            margin-left: 25px;
          }
        }

        label {
          display: flex;
          padding-left: 0;
          color: $primary-color;
          gap: 8px;
          outline: none;
        }

        .ui.checkbox {
          label::after {
            background: transparent;
            color: $secondary-color;
            content: '\e800' !important;
            opacity: 0;
            transform: scale(1);
            transition: opacity 0.2s ease;
          }

          label::before {
            position: static;
            display: block;
            border: 1px solid rgba(216, 227, 230, 0.7);
            border-radius: 2px;
            box-shadow: none;
            transition:
              border 0.2s ease,
              background 0.2s ease;
          }
        }

        .ui.checkbox.checked label::before {
          border: 1px solid rgba(29, 155, 240, 0.1);
          background: rgba(29, 155, 240, 0.1);
        }

        .ui.checkbox.checked label::after {
          background: transparent;
          opacity: 1;
        }
      }
    }

    .search-results-count-sort {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.5rem;
      color: $primary-color;

      @media screen and (max-width: 640px) {
        flex-direction: column;
      }

      .search-details {
        padding: 0 32px;

        span {
          color: $primary-color;
          font-size: 18px;

          &:last-child {
            font-weight: 300;
          }
        }
      }

      .sort-label {
        margin-right: 24px;
      }

      .search-sort-wrapper {
        margin: 0;
      }

      .search-sort-on {
        margin: 0;
      }

      .search-sort-wrapper button {
        display: none;
      }

      .react-select__control {
        display: flex;
        display: flex;
        height: auto;
        align-items: center;
        border: 1px solid rgba(216, 227, 230, 0.7);
        border-radius: 10px;
        background: transparent;

        &.react-select__control--is-focused,
        &.react-select__control--is-menu-open {
          border: 1px solid rgba($primary-color, 0.3);
        }

        .react-select__value-container {
          padding: 20px;
        }

        .react-select__single-value {
          color: $primary-color;
        }

        .react-select__indicator {
          width: 20px;
          height: 20px;
          padding: 0;
          border-radius: 999px;
          margin: 12px;
          background: rgba($primary-color, 0.1);

          svg {
            margin-top: -2px;
            fill: $primary-color !important;
          }
        }
      }
    }

    @media only screen and (min-width: $tablet-breakpoint) {
      .facets {
        flex-direction: column;

        .search-wrapper {
          margin-bottom: 60px;
        }
      }

      .search-results-count-sort {
        margin-top: 0;
      }
    }
  }

  .block.search {
    .ui.accordion.filter-listing {
      margin-bottom: 0;

      .filter-list-header {
        // width: fit-content;
        right: 0;
        padding: 12px;
        border: 1px solid rgba(216, 227, 230, 0.7);
        border-radius: 10px;
        margin-top: 1px;
        background: transparent;

        button.small.basic.button {
          padding: 10px 20px;
          border-radius: 5px;
          margin-left: 15px;
          background: $primary-color !important;
          color: white !important;

          &:hover {
            background: $secondary-color !important;
          }
        }
      }

      .filter-list-title svg {
        border-radius: 100%;
        margin-right: 0.4em;
        background: rgba($secondary-color, 0.1);
        color: $secondary-color;
      }

      .filter-list-content {
        padding-left: 30px;
      }

      .filter-list-group {
        flex-flow: wrap;

        .label-title,
        .ui.label {
          margin: 0 5px 0 0;
          font-size: 14px;
        }

        .ui.label:hover {
          background: #edf4f6;
        }
      }
    }

    .search-details {
      line-height: 60px;
    }

    .listing-item {
      &.listing-item--event {
        padding-bottom: 0 !important;
      }

      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}
@media screen and (max-width: $narrow-container-width) {
  .block.search .ui.stackable.searchBlock-facets {
    .ui[class*='three column'].grid > .column:not(.row),
    .ui[class*='three column'].grid > .row > .column {
      width: 50%;
    }
  }
}
